@use "../variables.scss" as *;

.sjs_sp_placeholder {
    @include useEditorFontSize;
    color: $font-questionplaceholder-color;
    font-size: $font-editorfont-size;
    line-height: multiply(1.5, $font-editorfont-size);
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
}

.sjs_sp_container {
    position: relative;
    max-width: 100%;
    border: 1px dashed $border;
    box-sizing: content-box;
    & > div > canvas:focus {
        outline: none;
    }
}

.sd-question--readonly,
.sd-question--preview {
    .sjs_sp_container {
        border: none;
    }

    .sjs_sp_placeholder {
        color: $foreground;
    }
}
.sjs_sp_controls {
    position: absolute;
    left: 0;
    bottom: 0;
    & > button {
        user-select: none;
    }
    &.sd-signaturepad__controls {
        right: calcSize(1);
        top: calcSize(1);
        left: auto;
        bottom: auto;
    }
}

.sd-question--signature.sd-question--error {
    .sjs_sp_placeholder {
        background-color: $red-light;
    }
}

.sd-signaturepad__background-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.sd-signaturepad__loading-indicator {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .sd-loading-indicator {
        position: absolute;
        right: calcSize(1);
        top: calcSize(1);
    }
}
.sjs_sp_canvas {
    position: relative;
    max-width: 100%;
    display: block;
}
.sjs_sp__background-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
}