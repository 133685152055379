@use "../variables.scss" as *;

.sd-radio__decorator {
  border-radius: 50%;

  &:after {
    content: " ";
    display: block;
    width: calcSize(1);
    height: calcSize(1);
    border-radius: 50%;
    background-color: transparent;
    transition: background-color $transition-duration;
  }
}
.sd-radio--checked .sd-radio__decorator:after {
  content: " ";
  display: block;
  width: calcSize(1);
  height: calcSize(1);
  border-radius: 50%;
  background-color: $primary-foreground;
}
.sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
  background-color: $border;
}

.sd-radio--checked.sd-radio--readonly .sd-radio__decorator:after {
  background-color: $foreground;
}

.sd-radio--checked.sd-radio--preview .sd-radio__decorator {
  &:after {
    display: none;
  }

  .sd-radio__svg {
    fill: $foreground;
    display: block;
    width: calcSize(2.5);
    height: calcSize(2.5);
  }
}

.sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator:after {
  background-color: $primary;
}
.sd-radio__svg {
  display: none;
}
