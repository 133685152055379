@use "../variables.scss" as *;

.sd-body__navigation {
  .sd-btn {
    padding: calcSize(2) calcSize(8);
    font-size: $font-questiontitle-size;
    line-height: multiply(1.5, $font-questiontitle-size);
  }
}

.sd-root--compact .sd-body__navigation {
  .sd-btn:not(.sd-btn--action) {
    background-color: $background-dim-light;
  }
  .sd-btn:not(.sd-btn--action):hover {
    background-color: $background-dim-dark;
  }
}

.sd-root-modern--mobile .sd-body__navigation {
  .sv-action:not(.sv-action--hidden),
  .sd-btn {
    flex-grow: 1;
  }

  .sd-btn {
    padding: calcSize(2) calcSize(4);
  }
}
.sd-body__navigation .sv-action--hidden {
  display: none;
}
