@use "../variables.scss" as *;

.sv-dragged-element-shortcut {
  height: calcSize(3);
  min-width: calcSize(12.5);
  border-radius: calcSize(4.5);
  background-color: $background;
  padding: calcSize(2);
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  font-family: $font-family;
  font-size: calcFontSize(1);
  padding-left: calcSize(2.5);
  line-height: calcLineHeight(1.5);
}

.sv-matrixdynamic__drag-icon {
  padding-top: calcSize(1.75);
}

.sv-matrixdynamic__drag-icon:after {
  content: " ";
  display: block;
  height: calcSize(0.75);
  width: calcSize(2.5);
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: calcSize(1.25);
  cursor: move;
  margin-top: calcSize(1.5);
}

.sv-matrixdynamic-dragged-row {
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  font-family: $font-family;

  .sd-table__row {
    box-shadow: $shadow-large, $shadow-medium;
    background-color: $background;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    line-height: 0;
  }

  .sd-table__cell.sd-table__cell--drag {
    >div {
      background-color: $question-background;
      min-height: calcSize(6);
    }
  }
}

.sd-table__cell--header,
.sd-table__cell {
  &.sd-table__cell--drag {
    padding-right: 0;
    padding-left: 0;
  }
}

.sd-question--mobile {

  .sd-table__cell--header,
  .sd-table__cell {
    &.sd-table__cell--drag {
      display: none;
    }
  }
}

.sv-matrix-row--drag-drop-ghost-mod td {
  background-color: $background-dim;
}

.sv-matrix-row--drag-drop-ghost-mod td>* {
  visibility: hidden;
}

.sv-drag-drop-choices-shortcut {
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  min-width: 100px;
  max-width: 400px;

  .sv-ranking-item {
    height: calcSize(6);

    .sv-ranking-item__text {

      .sv-string-viewer,
      .sv-string-editor {
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.sv-drag-drop-choices-shortcut__content.sv-drag-drop-choices-shortcut__content {
  min-width: 100px;
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1)), var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  border-radius: calc(4.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  padding-right: calc(2* var(--sjs-base-unit, var(--base-unit, 8px)));
  margin-left: 0;
}

.sv-drag-drop-image-picker-shortcut {
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1)), var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  padding: calc(0.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  border-radius: calc(0.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
}