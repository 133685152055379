@use "../variables.scss" as *;

.sd-selectbase {
  border: none;
  margin: 0;
  padding: 0;
  //prevent comment to resize over question area
  min-inline-size: 0;
  min-width: 0;
}

.sd-selectbase--row {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: calcSize(4);
}

.sd-selectbase--multi-column {
  display: flex;
  flex: 1 1 0px;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 2px;
  margin: 0 -2px;
}

.sd-selectbase__label {
  display: inline-flex;
  position: relative;
  gap: calcSize(1);
  vertical-align: top;
  max-width: 100%;
}

.sd-selectbase__column {
  vertical-align: top;
}

.sd-selectbase__column {
  display: block;
  box-sizing: border-box;
  flex: 1 1 0px;
  max-width: 100%;
}

.sd-selectbase__column:not(:last-child) {
  padding-right: calcSize(2);
}

// Copied from stylesmanager
.sd-selectbase__column.sv-q-column-1 {
  width: 100%;
}

$item-fade-in-duration: var(--sjs-ranking-fade-in-duration, 100ms);
$item-move-in-duration: var(--sjs-ranking-move-in-duration, 150ms);
$item-fade-in-delay: var(--sjs-ranking-fade-in-delay, 150ms);
$item-fade-out-duration: var(--sjs-ranking-fade-out-duration, 100ms);
$item-move-out-duration: var(--sjs-ranking-move-out-duration, 150ms);
$item-move-out-delay: var(--sjs-ranking-move-out-delay, 0ms);

.sd-item--enter,
.sd-item--leave {
  animation-name: moveInWithOverflow, fadeIn;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: var(--animation-direction);
  animation-duration: var(--move-animation-duration), var(--fade-animation-duration);
  animation-delay: var(--move-animation-delay), var(--fade-animation-delay);
}

.sd-item--enter {
  --animation-direction: normal;
  --move-animation-duration: #{$item-move-in-duration};
  --move-animation-delay: 0s;
  --fade-animation-duration: #{$item-fade-in-duration};
  --fade-animation-delay: #{$item-fade-in-delay};
}
.sd-item--leave {
  --animation-direction: reverse;
  --move-animation-duration: #{$item-move-out-duration};
  --move-animation-delay: #{$item-move-out-delay};
  --fade-animation-duration: #{$item-fade-out-duration};
  --fade-animation-delay: 0s;
}

div[class*="sv-q-column-"] {
  &:not(:first-of-type) {
    .sd-item--enter {
      --move-animation-duration: 0s;
      --fade-animation-delay: 0s;
    }
  }
}
