@use "../variables.scss" as *;

$popup-overlay-height: var(--sv-popup-overlay-height, 100vh);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$reverse-ease-out: cubic-bezier(0.42, 0, 1, 1);

sv-popup {
  display: block;
  position: absolute;
  // z-index: -1;
}

.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  outline: none;
  z-index: 2000;
  height: 100vh;
}

.sv-dropdown-popup {
  height: 0;
}

.sv-popup.sv-popup-inner {
  height: 0;
}

.sv-popup-inner > .sv-popup__container {
  margin-top: calcSize(-1);
}

.sv-list__item--with-icon .sv-popup-inner > .sv-popup__container {
  margin-top: calcSize(-0.5);
}

.sv-popup--menu-popup {
  & > .sv-popup__container {
    background: var(--lbr-popup-menu-background-color-global, $background-dim);
    border-radius: var(--lbr-popup-menu-corner-radius, calcCornerRadius(1));
    box-shadow: $shadow-medium, $shadow-large;
  }

  & > .sv-popup__container > .sv-popup__body-content {
    background-color: var(--lbr-popup-menu-background-color, $background);
    border-radius: var(--lbr-popup-menu-corner-radius, calcCornerRadius(1));
    // padding: calcSize(1) 0;
    height: 100%;
  }
}

.sv-popup__container {
  position: absolute;
  padding: 0;
}

.sv-popup__content {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
}

.sv-popup__body-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 100vw;
}

.sv-popup--modal-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-semitransparent;

  padding: calcSize(4) calcSize(15) calcSize(8);
  box-sizing: border-box;
}

.sv-popup--modal-popup {
  & > .sv-popup__container {
    position: static;
    display: flex;
    background-color: var(--lbr-dialog-background-color, $background-dim-light);
    border-radius: var(--lbr-dialog-corner-radius, calcCornerRadius(2));
    box-shadow:
      var(--lbr-dialog-shadow-2-offset-x, 0px) var(--lbr-dialog-shadow-2-offset-y, 2px)
        var(--lbr-dialog-shadow-2-blur, 6px) var(--lbr-dialog-shadow-2-spread, 0px)
        var(--lbr-dialog-shadow-2-color, rgba(0, 0, 0, 0.1)),
      var(--lbr-dialog-shadow-1-offset-x, 0px) var(--lbr-dialog-shadow-1-offset-y, 8px)
        var(--lbr-dialog-shadow-1-blur, 16px) var(--lbr-dialog-shadow-1-spread, 0px)
        var(--lbr-dialog-shadow-1-color, rgba(0, 0, 0, 0.1));
  }

  & > .sv-popup__container > .sv-popup__body-content {
    padding: calcSize(4);
    height: auto;
    min-width: 452px;
    gap: calcSize(4);
  }

  .sv-popup__body-footer .sv-modal-footer-action-bar {
    overflow: visible;
  }

  .sv-popup__scrolling-content {
    padding: 2px;
    margin: -2px;
  }
}

.sd-root-modern--mobile .sv-popup--modal-popup .sv-popup__body-content {
  min-width: auto;
}

.sv-popup--confirm {
  .sv-popup__body-content .sv-string-viewer{
    color: $font-editorfont-color;
    align-self: self-start;
    /* UI/Default */
    font-family: $font-family;
    font-size: calcFontSize(1);
    font-style: normal;
    font-weight: 400;
    line-height: calcLineHeight(1.5);
    /* 150% */
  }
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &,
  * {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: $background-dim;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-light;
    }
  }
}

.sv-popup--show-pointer.sv-popup--top {
  .sv-popup__pointer {
    transform: translate(calcSize(-1)) rotate(180deg);
  }
}

.sv-popup--show-pointer.sv-popup--bottom {
  .sv-popup__pointer {
    transform: translate(calcSize(-1), calcSize(-1));
  }
}

.sv-popup--show-pointer.sv-popup--right .sv-popup__container {
  transform: translate(calcSize(1));

  .sv-popup__pointer {
    transform: translate(-12px, -4px) rotate(-90deg);
  }
}

.sv-popup--show-pointer.sv-popup--left .sv-popup__container {
  transform: translate(calcSize(-1));

  .sv-popup__pointer {
    transform: translate(-4px, -4px) rotate(90deg);
  }
}

.sv-popup__pointer {
  display: block;
  position: absolute;

  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-left: calcSize(1) solid transparent;
    border-right: calcSize(1) solid transparent;
    border-bottom: calcSize(1) solid $background;
    align-self: center;
  }
}

.sv-popup__body-header {
  font-family: Open Sans;
  font-size: calcFontSize(1.5);
  line-height: calcLineHeight(2);
  font-style: normal;
  font-weight: 700;
  color: $foreground;
}

.sv-popup__body-footer {
  display: flex;
}

.sv-popup__body-footer .sv-action-bar {
  gap: calcSize(1.5);
  overflow: visible;
}

.sv-popup--menu-phone,
.sv-popup--menu-tablet {
  z-index: 2001;
  padding: 0;

  width: 100%;
  height: $popup-overlay-height;

  .sv-popup__body-footer-item {
    width: 100%;
  }

  .sv-popup__body-footer .sv-action-bar {
    width: 100%;
    justify-content: flex-start;

    .sv-action {
      flex: 0 0 auto;
    }
  }

  .sv-popup__body-footer {
    padding: var(--lbr-popup-menu-footer-padding-top, calcSize(0.5)) var(--lbr-popup-menu-footer-padding-right, 0px)
      var(--lbr-popup-menu-footer-padding-bottom, calcSize(0.5)) var(--lbr-popup-menu-footer-padding-left, 0px);
    border-top: var(--lbr-popup-menu-footer-border-width-top, 1px) solid
      var(--lbr-popup-menu-footer-border-color, $border-light);
    background: var(--lbr-popup-menu-footer-background-color, $background-dim-light);
  }

  .sv-list__filter {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: calcSize(2);
  }

  .sv-list {
    flex-grow: 1;
  }

  .sv-list__filter-icon {
    position: static;
    height: calcSize(3);
  }

  .sv-list__empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }

  .sv-list__filter-clear-button {
    height: calcSize(3);
    width: calcSize(3);
    padding: calcSize(0.5);
    appearance: none;
    border: none;
    border-radius: 100%;
    background-color: transparent;

    svg {
      height: calcSize(2);
      width: calcSize(2);

      use {
        fill: $foreground-light;
      }
    }

    &:hover {
      border-radius: var(--lbr-popup-menu-search-clear-button-corner-radius, 1024px);
      background: var(--lbr-popup-menu-search-clear-button-background-color-hovered, var(--sjs-special-red-light, rgba(229, 10, 62, 0.1)));

      use {
        fill: var(--lbr-popup-menu-search-clear-button-icon-color-hovered, var(--sjs-special-red, #E50A3E));
      }
    }
  }

  .sv-list__input {
    color: $foreground-light;
    font-size: max(16px, calcFontSize(1));
    line-height: max(24px, calcLineHeight(1.5));
    font-family: $font-family;
    padding: calcSize(0.5) calcSize(0.5) calcSize(0.5) calcSize(1);
  }

  .sv-list__item:hover,
  .sv-list__item:focus,
  .sv-list__item--focused {
    .sv-list__item-body {
      background: $background;
    }

    &.sv-list__item--selected {
      .sv-list__item-body {
        background: $primary;
        color: $primary-foreground;
        font-weight: 600;
      }
    }
  }

  &.sv-multi-select-list {
    .sv-list__item:hover,
    .sv-list__item:focus,
    .sv-list__item--focused {
      &.sv-list__item--selected {
        .sv-list__item-body {
          background: $primary-light;
          color: $foreground;
          font-weight: 400;
        }
      }
    }
  }
}

.sv-popup--menu-phone {
  & > .sv-popup__container {
    width: 100%;
    height: calc(var(--sv-popup-overlay-height, 100vh));
    max-width: 100vw;
    max-height: calc(var(--sv-popup-overlay-height, 100vh));
    // padding-top: 0;
    border: unset;
    box-shadow: unset;
    box-sizing: content-box;
    background: var(--lbr-popup-menu-background-color-global, $background-dim);
  }

  & > .sv-popup__container > .sv-popup__body-content {
    background-color: var(--lbr-popup-menu-background-color, $background);
    max-height: $popup-overlay-height;
    max-width: 100vw;
    height: calc(var(--sv-popup-overlay-height, 100vh));
  }
}

.sv-popup--menu-tablet {
  background: $background-semitransparent;

  & > .sv-popup__container {
    // padding-top: 0;
    border: unset;
    box-sizing: content-box;
    background: var(--lbr-popup-menu-background-color-global, $background-dim);

    --sv-popup-overlay-max-height: calc(var(--sv-popup-overlay-height, 100vh) - #{$base-unit} * 8);
    --sv-popup-overlay-max-width: calc(100% - #{$base-unit} * 8);
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-height: var(--sv-popup-overlay-max-height);
    min-height: min(var(--sv-popup-overlay-max-height), calcSize(30));
    height: auto;
    width: auto;
    min-width: min(calcSize(40), var(--sv-popup-overlay-max-width));
    max-width: var(--sv-popup-overlay-max-width);
    border-radius: var(--lbr-popup-menu-corner-radius, calcCornerRadius(1));
    overflow: hidden;
    box-shadow: $shadow-medium, $shadow-large;
  }

  & > .sv-popup__container > .sv-popup__body-content {
    background-color: var(--lbr-popup-menu-background-color, $background);
    max-width: 100vw;
    max-height: calc(var(--sv-popup-overlay-height, 100vh) - var(--sjs-base-unit, var(--base-unit, 8px)) * 8);
    min-height: min(var(--sv-popup-overlay-max-height), calcSize(30));
    height: auto;
  }

  .sv-popup__content,
  .sv-popup__scrolling-content,
  .sv-list__container {
    flex-grow: 1;
  }
}

.sv-popup--visible {
  opacity: 1;
}

.sv-popup--hidden {
  opacity: 0;
}

.sv-popup--enter {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
}

.sv-popup--modal-popup.sv-popup--enter {
  animation-timing-function: $ease-out;
  animation-duration: 0.25s;
}

.sv-popup--leave {
  animation-direction: reverse;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
}

.sv-popup--modal-popup.sv-popup--leave {
  animation-timing-function: $reverse-ease-out;
  animation-duration: 0.25s;
}

.sv-popup--hidden {
  opacity: 0;
}

@keyframes modalMoveUp {
  from {
    transform: translateY(64px);
  }

  to {
    transform: translateY(0);
  }
}

.sv-popup--modal-popup {
  &.sv-popup--leave,
  &.sv-popup--enter {
    .sv-popup__container {
      animation-name: modalMoveUp;
      animation-timing-function: $ease-out;
      animation-fill-mode: forwards;
      animation-duration: 0.25s;
    }
  }
  &.sv-popup--leave .sv-popup__container {
    animation-direction: reverse;
    animation-timing-function: $reverse-ease-out;
  }
}
