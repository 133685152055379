@use "../variables.scss" as *;

.sd-comment {
  display: block;
  height: auto;
  min-width: calcSize(6);
  min-height: calcSize(6);
  max-width: 100%;
}

.sd-comment__content {
  position: relative;
}
