@use "variables.scss" as *;

@mixin smallBold {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcFontSize(0.75);
  line-height: calcLineHeight(1);
}

@mixin defaultBold {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
}

@mixin defaultFont {
  font-family: $font-family;
  font-style: normal;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
}

@mixin borderLight {
  border: calcSize(0.25) solid $border-light;
}

@mixin articleXXLargeFont {
  font-size: var(--sjs-article-font-xx-large-fontSize, calc(4 * (#{$font-size})));
  text-decoration: var(--sjs-article-font-xx-large-textDecoration, "none");
  font-family: $font-family;
  font-weight: var(--sjs-article-font-xx-large-fontWeight, 700);
  font-style: var(--sjs-article-font-xx-large-fontStyle, "normal");
  font-stretch: var(--sjs-article-font-xx-large-fontStretch, "normal");
  letter-spacing: var(--sjs-article-font-xx-large-letterSpacing, 0);
  line-height: var(--sjs-article-font-xx-large-lineHeight, 64px);
  text-indent: var(--sjs-article-font-xx-large-paragraphIndent, 0px);
  text-transform: var(--sjs-article-font-xx-large-textCase, "none");
}

@mixin articleXLargeFont {
  font-size: var(--sjs-article-font-x-large-fontSize, calc(3 * (#{$font-size})));
  text-decoration: var(--sjs-article-font-x-large-textDecoration, "none");
  font-family: $font-family;
  font-weight: var(--sjs-article-font-x-large-fontWeight, 700);
  font-style: var(--sjs-article-font-x-large-fontStyle, "normal");
  font-stretch: var(--sjs-article-font-x-large-fontStretch, "normal");
  letter-spacing: var(--sjs-article-font-x-large-letterSpacing, 0);
  line-height: var(--sjs-article-font-x-large-lineHeight, 56px);
  text-indent: var(--sjs-article-font-x-large-paragraphIndent, 0px);
  text-transform: var(--sjs-article-font-x-large-textCase, "none");
}

@mixin articleLargeFont {
  font-size: var(--sjs-article-font-large-fontSize, calc(2 * (#{$font-size})));
  text-decoration: var(--sjs-article-font-large-textDecoration, "none");
  font-family: $font-family;
  font-weight: var(--sjs-article-font-large-fontWeight, 700);
  font-style: var(--sjs-article-font-large-fontStyle, "normal");
  font-stretch: var(--sjs-article-font-large-fontStretch, "normal");
  letter-spacing: var(--sjs-article-font-large-letterSpacing, 0);
  line-height: var(--sjs-article-font-large-lineHeight, 40px);
  text-indent: var(--sjs-article-font-large-paragraphIndent, 0px);
  text-transform: var(--sjs-article-font-large-textCase, "none");
}

@mixin articleMediumFont {
  font-size: var(--sjs-article-font-medium-fontSize, calc(1.5 * (#{$font-size})));
  text-decoration: var(--sjs-article-font-medium-textDecoration, "none");
  font-family: $font-family;
  font-weight: var(--sjs-article-font-medium-fontWeight, 700);
  font-style: var(--sjs-article-font-medium-fontStyle, "normal");
  font-stretch: var(--sjs-article-font-medium-fontStretch, "normal");
  letter-spacing: var(--sjs-article-font-medium-letterSpacing, 0);
  line-height: var(--sjs-article-font-medium-lineHeight, 32px);
  text-indent: var(--sjs-article-font-medium-paragraphIndent, 0px);
  text-transform: var(--sjs-article-font-medium-textCase, "none");
}

@mixin articleDefaultFont {
  font-size: var(--sjs-article-font-default-fontSize, #{$font-size});
  text-decoration: var(--sjs-article-font-default-textDecoration, "none");
  font-family: $font-family;
  font-weight: var(--sjs-article-font-default-fontWeight, 400);
  font-style: var(--sjs-article-font-default-fontStyle, "normal");
  font-stretch: var(--sjs-article-font-default-fontStretch, "normal");
  letter-spacing: var(--sjs-article-font-default-letterSpacing, 0);
  line-height: var(--sjs-article-font-default-lineHeight, 28px);
  text-indent: var(--sjs-article-font-default-paragraphIndent, 0px);
  text-transform: var(--sjs-article-font-default-textCase, "none");
}

@mixin articleHtml {
  @include articleDefaultFont;
  color: $font-pagetitle-color;
  h1 {
    @include articleXXLargeFont;
  }

  h2 {
    @include articleXLargeFont;
  }

  h3 {
    @include articleLargeFont;
  }

  h4,
  h5,
  h6 {
    @include articleMediumFont;
  }

  td,
  span,
  div,
  p {
    @include articleDefaultFont;
  }

  a {
    color: $primary;
  }

  button {
    display: flex;
    align-items: center;
    padding: calcSize(1.5) calcSize(4);
    vertical-align: baseline;
    text-align: center;
    background-color: $background;
    border: none;
    border-radius: calcCornerRadius(1);
    cursor: pointer;
    user-select: none;
    outline: solid calcSize(0.25) transparent;

    color: $primary;
    font-weight: 600;
    font-style: normal;
    font-family: $font-family;
    font-size: $font-questiontitle-size;
    line-height: multiply(1.5, $font-questiontitle-size);

    box-shadow: $shadow-small, 0 0 0 0px $primary;
    transition: box-shadow $transition-duration;

    &:hover {
      background-color: $background-dark;
    }

    &:focus {
      box-shadow: $shadow-small-reset, 0 0 0 2px $primary;
    }

    span {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;
      // color: $primary;
      // font-weight: 600;
      // font-style: normal;
      // font-family: $font-family;
      // font-size: $font-questiontitle-size;
      // line-height: multiply(1.5, $font-questiontitle-size);
    }
  }
}

@mixin page_title {
  --page-title-font-size: var(--sjs-font-pagetitle-size, calc(1.5 * (var(--sjs-font-size, 16px))));
  font-family: $font-pagetitle-family;
  font-weight: $font-pagetitle-weight;
  font-size: var(--page-title-font-size);
  color: $font-pagetitle-color;
  position: static;
  line-height: multiply(1.33, var(--page-title-font-size));
  margin: 0 0 calcSize(0.5) 0px;
}

@mixin survey_title {
  --survey-title-font-size: var(--sjs-font-surveytitle-size, calc(2 * (var(--sjs-font-size, 16px))));
  font-size: var(--survey-title-font-size);
  line-height: multiply(1.25, var(--survey-title-font-size));
}

@mixin survey_description {
  --survey-description-font-size: var(--sjs-font-surveydescription-size, var(--sjs-font-size, 16px));
  font-size: var(--survey-description-font-size);
  line-height: multiply(1.5, var(--survey-description-font-size));
}

@mixin header_title {
  --header-title-font-size: var(--sjs-font-headertitle-size, calc(2 * (var(--sjs-font-size, 16px))));
  font-size: var(--header-title-font-size);
  line-height: multiply(1.25, var(--header-title-font-size));
}

@mixin header_description {
  --header-description-font-size: var(--sjs-font-headerdescription-size, 20px);
  font-size: var(--header-description-font-size);
  line-height: multiply(1.5, var(--header-description-font-size));
}

@mixin page_description {
  font-family: $font-pagedescription-family;
  font-weight: $font-pagedescription-weight;
  font-size: $font-pagedescription-size;
  color: $font-pagedescription-color;
  position: static;
  line-height: multiply(1.5, $font-pagedescription-size);
  margin: 0 0 calcSize(0.5) 0;
}

@mixin num_inline {
  float: none;
  margin-inline-start: 0;
  width: auto;
  padding-inline-start: 0;
  padding-inline-end: 0;

  & + span {
    float: none;
    width: auto;
  }
}

@mixin scrollRules {
  &,
  * {
    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: $border transparent;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 32px;
      background-color: $border;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 2px solid rgba(0, 0, 0, 0);
      background-color: $foreground-light;
    }
  }
}