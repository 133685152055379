@use "../variables.scss" as *;

.sv_progress-toc {
  padding: var(--lbr-toc-padding-top, calcSize(1)) var(--lbr-toc-padding-right, calcSize(1))
    var(--lbr-toc-padding-bottom, calcSize(1)) var(--lbr-toc-padding-left, calcSize(1));
  background: var(--lbr-toc-background-color, $background);

  min-width: calcSize(32);
  max-width: calcSize(42);
  height: 100%;
  box-sizing: border-box;

  .sv-list {
    padding: 0;
  }

  .sv-list__item.sv-list__item--selected .sv-list__item-body {
    background: $primary-light;
    color: $foreground;
    font-weight: 400;
  }

  .sv-list__item span {
    white-space: break-spaces;
  }

  .sv-list__item-body {
    padding-inline-start: calcSize(2);
    padding-inline-end: calcSize(2);
    border-radius: calcCornerRadius(1);
    padding-top: calcSize(1.5);
    padding-bottom: calcSize(1.5);
  }

  use {
    fill: $foreground-light;
  }
}

.sv_progress-toc--left {
  border-right: var(--lbr-toc-border-width-right, 1px) solid var(--lbr-toc-border-color, $border);
}

.sv_progress-toc--right {
  border-left: var(--lbr-toc-border-width-right, 1px) solid var(--lbr-toc-border-color, $border);
}

.sv_progress-toc--mobile {
  position: fixed;
  top: calcSize(3);
  right: calcSize(4);
  width: auto;
  min-width: auto;
  height: auto;
  background-color: $background-dim;
  z-index: 15;
  border-radius: calcSize(3);

  &>div {
    width: calcSize(3);
    height: calcSize(3);
  }

  &:hover {
    background-color: $background-dim;
  }
}

.sd-title+.sv-components-row,
.sd-title~.sv-components-row {
  &>.sv-components-column .sv_progress-toc:not(.sv_progress-toc--mobile) {
    margin-top: 2px;
  }
}

.sv_progress-toc.sv_progress-toc--sticky {
  position: sticky;
  height: auto;
  overflow-y: auto;
  top: 0;
}